import React from 'react';
import './Chatbot.css'; // Importe o arquivo CSS
import ChatbotImg from '../../assets/imgs/WhatsApp Image 2024-04-10 at 16.42.38.jpeg';

function Chatbot() {

    return (
        <div className="chatbot-wrapper">
            <div className="chatbot-container">
                <div className='chatbot-header'>
                    <h2>Converse e tire dúvidas com uma Inteligência Artificial treinada com o livro "Manual Prático de Investigação Defensiva": </h2>
                   
                </div>
                <iframe scrolling="no" src="https://www.decimatecnologia.com.br/bulhoes" title='livrochatbot' >
                   
                </iframe>
            
            </div>
        </div>
    );
}

export default Chatbot;
