import React from "react";

const VersaoImpressa = () => {
    return (
        <div className="impresso-container home-container alt">
            <h2>Adquira a versão impressa</h2>
            <div className="impresso-links">
                <a href="https://www.amazon.com.br/Pr%C3%A1tico-Investiga%C3%A7%C3%A3o-Defensiva-Gabriel-Bulh%C3%B5es/dp/658643999X/ref=asc_df_658643999X/?tag=googleshopp00-20&linkCode=df0&hvadid=581934698281&hvpos=&hvnetw=g&hvrand=8365196987195905814&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9101492&hvtargid=pla-1808476844803&psc=1&mcid=26abb10af49e3f8fbdba54bf73c13ad0" target="_blank" rel="noreferrer">Amazon</a>
                <a href="https://loja.umlivro.com.br/manual-pratico-de-investigacao-defensiva-6513705/p" target="_blank" rel="noreferrer">Um Livro</a>
                <a href="https://www.americanas.com.br/produto/5952423650/manual-pratico-de-investigacao-defensiva?opn=YSMESP&offerId=6328cc289064f2befb2fd116&srsltid=AfmBOopf-O9Xzn_BYR03wztDq825uCmDYc4ieorGXp3XdE_iKLPz_C6HzSw" target="_blank" rel="noreferrer">Americanas</a>
                <a href="https://www.carrefour.com.br/manual-pratico-de-investigacao-defensiva-mp930015460/p" target="_blank" rel="noreferrer">Carrefour</a>

            </div>
        </div>
    );
};

export default VersaoImpressa;

