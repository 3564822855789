import React, { useState } from "react";

function Descricao() {
  const [expandir, setExpandir] = useState(false);

  const toggle = () => {
    setExpandir(!expandir);
  };
  return (
    <div className="descricao">
      <h2>Descrição</h2>
      <p>
        Quando o advogado criminalista sai de seu gabinete e vai em busca de
        provas, ou ainda quando diligencia ativamente, ainda que sentado no seu
        computador dentro do escritório, e em muitas outras situações, está-se
        realizando investigação defensiva.
      </p>
      <p>
        Ocorre que, por uma ausência de previsão legal e/ou administrativa, o
        assunto não é tratado a contento - sequer pela Academia. Por outro lado,
        não se pode descurar dos parâmetros normativos postos, os quais em uma
        interpretação sistêmica, permitem o exercício da investigação defensiva.
      </p>
      {expandir ? (
        <>
          <p>
            A discussão tende a se avolumar, no entanto, quando a demanda
            prático-profissional começa a exigir dos causídicos a adoção de
            medidas nesse sentido. E só aí se dá conta de que se faz tudo
            intuitivamente, sem um arcabouço teórico ou uma metodologia a ser
            aplicada.
          </p>
          <p>
            Contudo, esse novo cenário apresenta um ônus, pois a advocacia vai
            precisar se aparelhar e se instruir com conhecimentos, profissionais
            e ferramentas para produzir um material de alta qualidade. Apesar de
            existirem técnicas investigativas de baixo ou nulo custo, por
            exemplo, já desponta a necessidade dessa discussão invadir as
            Defensorias Públicas.
          </p>
          <p>
            Tudo isso impacta e implica em uma mudança cultural que talvez
            demore mais de uma geração para ocorrer, mas vai; e a catalização
            desse processo depende dos atores envolvidos. No mais, todo início é
            difícil.
          </p>
          <p>
            A implementação da Investigação Defensiva no Brasil vai enfrentar
            inúmeras resistências, tendo em vista que a Defesa já encontra
            resistências em variados graus, desde o direito de acesso integral
            aos autos das investigações estatais.
          </p>
          <p>
            Visando, pois, fornecer algum contributo para esse momento
            embrionário do surgimento das práticas de investigação defensiva no
            Brasil, a presente obra pretende fornecer, para além de um arcabouço
            comparado, teórico e normativo, um conjunto de informações sobre
            questões práticas da advocacia investigativa.
          </p>
        </>
      ) : (
        <></>
      )}
      <button onClick={toggle}>
        {expandir ? "Recolher Texto" : "Carregar mais"}
      </button>
    </div>
  );
}

export default Descricao;
