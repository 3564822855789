import React, { useState, useRef } from "react";
import PDF from '../../assets/ManualPraticodeInvestigacaoDefensiva.pdf';

const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");
  
  const [celular, setCelular] = useState("");
  const [nome, setNome] = useState("");
  const emailRef = useRef(null);
  const [aviso, setAviso] = useState(false);

  const [showStatus, setShowStatus] = useState(false);

  const [avisoRegular, setAvisoRegular] = useState("");
    const [avisoErro, setAvisoErro] = useState("");

  const [inscrito, setInscrito] = useState(true);
  const [response, setResponse] = useState("");

  if (status === 'success') {
    window.open(PDF, '_blank');
  }


  const handleSubmit = () => {
    if (email && email.includes("@")) {
      if (inscrito) {
        setShowStatus(true);
        setAvisoRegular("");
        
          onValidated({
            EMAIL: email,
          });
      } else {
        setShowStatus(false);
      }

      setAviso(false);
      handleRegularSubmit();

    } else {
      setShowStatus(false);
      setAvisoRegular("");
      setAviso(true);
      
        setAvisoErro('');
    }
  };

  const origem = 'Download do Ebook: Manual Prático de Investigação Defensiva, 1 Edição';

  const handleRegularSubmit = async () => {
    try {
      const response = await fetch(
        "https://jacquesgomes.com.br/gb/salvar-assinante",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ celular, email, nome, origem }),
        }
      );

      if (!response.ok && !inscrito) {
        setAvisoErro("Erro ao enviar o formulário. Por favor, confira se o email está correto!");

        throw new Error("Erro ao enviar número de telefone.");
      }

        if (response.ok && !inscrito){
            window.open(PDF, '_blank');
      }

      if (!inscrito) {
        setAvisoErro('');
        setAvisoRegular("Formulário enviado com sucesso!");
      }

    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <div class="newsletter-feedback">
        {showStatus && (
          <>
            {" "}
            {status === "sending" && (
              <div style={{ color: "white" }}>Enviado...</div>
            )}
            {status === "error" && (
              <div
                style={{ color: "red" }}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
            {status === "success" && (
              <div
                style={{ color: "green" }}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}{" "}
          </>
        )}
        {aviso && (
          <p style={{ color: "red" }}>Por favor, insira um email válido</p>
        )}
              {avisoRegular && <p style={{ color: "green" }} >{avisoRegular}</p>}
              {avisoErro && <p style={{ color: "red" }}>{avisoErro}</p>}
      </div>

      <div className="newsletter-container">
        <label for="">
          Nome:
          <input
            type="text"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </label>

        <label for="">
          Email:
          <input
            ref={emailRef}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <label for="">
          Celular:
          <input
            type="text"
            value={celular}
            onChange={(e) => setCelular(e.target.value)}
          />
        </label>

        <div>
          <label className="newsletter-check">
            Inscrever-se na newsletter:
            <input
              type="checkbox"
              checked={inscrito}
              onChange={(e) => setInscrito(!inscrito)}
            />
          </label>
        </div>
      </div>
      <div className="news-btn">
        <button onClick={handleSubmit}>Baixar ebook</button>
      </div>
    </div>
  );
};

export default CustomForm;
