import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import './components/home/Home.css';
import Livro from "./assets/imgs/manualpraticoinvestigacaodefensiva2_capa.jpg";
import Form from "./components/home/Form";
import Chatbot from './components/home/Chatbot';
import Escritorio from "./components/home/Escritorio";


import './assets/styles/Reset.css';
import VersaoImpressa from "./components/home/VersaoImpressa";
import Descricao from "./components/home/Descricao";
import SobreAutor from "./components/home/SobreAutor";

function App() {
  const main = process.env.REACT_APP_MAIN;

  const inicio = useRef();
  const chatbot = useRef();
  const escritorio = useRef();
  const newsletter = useRef();

  const scrollToComponent = (refName) => {
    const refs = {
      inicio,
      escritorio,
      chatbot,
      newsletter,
    };

    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
      var yOffset;
      if (refName === "inicio") {
        yOffset = -180;
      } else {
        yOffset = -120;
      }

      const yCoordinate =
        selectedRef.current.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };

  return (
    <Router>
      <div className="App home-wrapper">
        <Header scrollToComponent={scrollToComponent} />
        <div ref={inicio}>
          <div className="home-container">
            <div className="left-col-container">
              <div><img src={Livro} alt="Ebook Investigação Defensiva"></img>
                <div class="home__shadow"></div>
              </div>
            </div>

            <div className="right-col-container">
              <Form />
            </div>
          </div>
        </div>

        <VersaoImpressa />

        <Descricao />

        <div ref={chatbot}>
          <Chatbot />
        </div>

        <div ref={escritorio}>
          <Escritorio />
        </div>


        <div>
          <SobreAutor />
        </div>

        <div ref={newsletter}>

          <Footer scrollToComponent={scrollToComponent} />
        </div>
      </div>
    </Router>
  );
}

export default App;
