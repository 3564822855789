import React, { useState } from 'react';
import './Form.css'; // Importe o arquivo CSS
import Demo from '../newsletter/DemoBook';

function Formulario() {

    return (
        <div className="formulario">
            <h2>Preencha o formulário para obter de forma gratuita o Ebook: Manual Prático de Investigação Defensiva, 1ª Edição</h2>
            <Demo />
        </div>
    );
}

export default Formulario;
